<template>
  <WhyPage />
</template>

<script>
import WhyPage from "@/components/view/WhyPage";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    WhyPage,
  },
  metaInfo: {
    title: "Waarom kiezen voor kantoorflex | Flexwerkplek | Kantoorflex ",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Waarom kiezen voor Kantoorflex? Flexibele werkplek ruimte huren en verhuren biedt alleen maar voordelen. Waar dan ook in Nederland goedkoop een flexplek huren.",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
};
</script>