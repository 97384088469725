<template>
  <div>
    <img src= @/assets/banner.jpg height="200" width="100%" />
    <v-container>
      <div class="space">
        <v-row>
          <v-col sm="12" md="7" xl="7"  data-aos="flip-up">
            <h2>Waarom Kantoorflex</h2>

            <p>
              Kantoorflex biedt flex werkplekken aan over heel Nederland. Een
              kantoor huren bij Kantoorflex is voordelig, aangezien wij een
              ruime keuze hebben aan kantoor werkplekken welke geschikt zijn
              vanaf één persoon. Onze flexibele kantoorruimtes zijn gevestigd op
              gevestigde multi-use business park locaties en zijn geschikt voor
              een grote verscheidenheid aan bedrijven. Ook kunnen bedrijven zich
              aanmelden als zij een flexwerkplek willen verhuren. Daardoor
              hebben wij een landelijke dekking van uiterst zorgvuldig
              ingerichte, flexibele werkplekken.
            </p>

            <v-btn color="info" class="ma-2 white--text" @click="goToContact()">
              Neem contact op
            </v-btn>
          </v-col>
          <v-col>
            <v-card class="mx-auto" max-width="400" elevation="9" shaped>
              <v-img
                class="white--text align-end"
                height="350px"
                src="@/assets/homepage/about.jpg"
              >
              </v-img>
            </v-card>
          </v-col>
        </v-row>

        <v-row style="margin-top:40px">
          <v-col>
            <v-card class="mx-auto" max-width="400" elevation="9" shaped>
              <v-img
                class="white--text align-end"
                height="350px"
                src="@/assets/flexoffice.jpg"
              >
              </v-img>
            </v-card>
          </v-col>
          <v-col sm="12" md="7" xl="7" data-aos="flip-down">
            <h5><b>Kantoor huren bij Kantoorflex</b></h5>

            <p>
              Van financiën tot rekrutering, van accountants tot zorgverleners -
              wat je sector ook is, wij hebben een kantoorruimte die aan je
              behoeften voldoet. Onze moderne, toegankelijke kantoren zijn
              geschikt voor bedrijven van alle soorten en maten. Wil je een
              kantoor huren bij Kantoorflex? Neem dan zeker contact op. Alle
              kantoren kunnen gemeubileerd of ongemeubileerd zijn, afhankelijk
              van je eisen. Ook sluiten we je aan op snelle internetdiensten en
              kunnen je aan de praat houden met op maat gemaakte
              telecompakketten.
            </p>

            <h5><b>Op zoek naar meer dan één locatie?</b></h5>
            <p>
              Ben je op zoek naar meer flexibiliteit en bereik zonder de
              keerzijde van een langdurige verbintenis of een kolossale ruimte?
              Of misschien moet je, als je personeel overschakelt op een
              veelzijdiger werkmodel, dichter bij de plek zijn waar ze wonen en
              waar je klanten zich bevinden? Kantoorflex heeft een toegewijd
              multi-site team dat naar je luistert om alle opties uit te werken.
              Om de perfecte portfolio van locaties en faciliteiten samen te
              stellen die je bedrijf helpen bloeien. Neem vandaag nog contact
              met ons team op voor een gratis offerte of meer informatie.
            </p>
            <v-btn color="info" class="ma-2 white--text" @click="goToContact()">
              Neem contact op
            </v-btn>
          </v-col>
        </v-row>

        <v-row style="margin-top:40px">
          <v-col sm="12" md="7" xl="7" data-aos="flip-left">
            <h5><b>Waarom een flexplek van Kantoorflex kiezen?</b></h5>

            <p>
              Veel van onze flexibele werkplekken worden geleverd met:<br />
              <span style="color:#64b5f6;"> &#x2713; </span> snelheid
              internet<br />
              <span style="color:#64b5f6;"> &#x2713; </span>Gratis parkeren<br />
              <span style="color:#64b5f6;"> &#x2713; </span>Telefoon
              beantwoording<br />
              <span style="color:#64b5f6;"> &#x2713; </span>Receptie &
              postbus<br />
              <span style="color:#64b5f6;"> &#x2713; </span>24-uurs toegang<br />
              <span style="color:#64b5f6;"> &#x2713; </span>Beveiliging<br />
              <span style="color:#64b5f6;"> &#x2713; </span>Vergaderzalen<br />
              <span style="color:#64b5f6;"> &#x2713; </span>Atrium &
              cafetaria<br />
              <span style="color:#64b5f6;"> &#x2713; </span>Modern ingerichte
              werkplekken<br />
            </p>

            <p>
              Bij Kantoorflex bieden we een keuze aan flexibele overeenkomsten
              en de mogelijkheid uit te breiden naar grotere eenheden als je
              bedrijf groeit. Ons doel is een flexibele en professionele
              werkomgeving te bieden om bloeiende bedrijfsgemeenschappen aan te
              moedigen te groeien.
            </p>
            <v-btn color="info" class="ma-2 white--text" @click="goToContact()">
              Ik wil meer informatie
            </v-btn>
          </v-col>
          <v-col>
            <v-card class="mx-auto" max-width="400" elevation="9" shaped>
              <v-img
                class="white--text align-end"
                height="350px"
                src="@/assets/officeperson.png"
              >
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({}),

  methods: {
    goToContact() {
      this.$router.push("/Contact");
    },
    goToAccount() {
      this.$router.push("/Inloggen");
    },
  },
};
</script>

<style scoped>
.space {
  margin-top: 15px;
  margin-bottom: 8px;
  border-style: none;
}
</style>
